import "bootstrap/dist/css/bootstrap-grid.min.css";
import "bootstrap/dist/css/bootstrap-reboot.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/main.css";

document.addEventListener("DOMContentLoaded", e => {
    document.querySelector(".header__burger").addEventListener("click", e => {
        e.currentTarget.classList.toggle("--active");
        document.querySelector(".burger__underlay").classList.toggle("--active");
        document.querySelector(".header__burger__wrapper").classList.toggle("--active");
        document.querySelector(".header__contacts").classList.toggle("--fixed");
    });
});